import { useEffect, useState } from 'react';
import { getAllLogs, getHourlyUsage } from 'apis/visualization'
import { isAdmin } from 'constants/const';

export interface AggregatedData {
    groupId: number;
    dataCount: number;
}

export interface RequestLog {
    _id: String;
    projectRefId: string;
    timestamp: string;
    logType: string;
    actionType: string;
    arContentId: string;
    analyticId: string;
}

// export const useApiUsageData = (): [AggregatedData[], boolean] => {

//     const [apiDataState, setapiDataState] = useState<AggregatedData[]>([]);
//     const [isFetching, setIsFetching] = useState(false);

//     useEffect(() => {
//         const fetchDataFromApi = async () => {
//             const obj = JSON.parse(localStorage.getItem("xr-arena-user")!);
//             if (!obj.firebaseUid) {
//                 return [];
//             }

//             try {
//                 setIsFetching(true);
//                 const apiData = await getApiUsage(obj.firebaseUid);
//                 setapiDataState(apiData);
//                 setIsFetching(false)
//             } catch (e) {
//                 console.log(e);
//                 setIsFetching(false)
//             }
//         };

//         if (localStorage.getItem("xr-arena-user")) {
//             fetchDataFromApi();
//         }

//     }, []); // Runs once

//     return [apiDataState, isFetching];
// };

export const useAllLogsData = (): [AggregatedData[], boolean] => {

    const [rawLogsState, setRawLogsState] = useState<AggregatedData[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            const obj = JSON.parse(localStorage.getItem("xr-arena-user")!);
            if (!obj.firebaseUid) {
                return [];
            }

            try {
                setIsFetching(true);
                const rawLogsData = await getAllLogs();
                setRawLogsState(rawLogsData);
                setIsFetching(false)
            } catch (e) {
                console.log(e);
                setIsFetching(false)
            }
        };

        if (localStorage.getItem("xr-arena-user")) {
            fetchDataFromApi();
        }

    }, []); // Runs once

    return [rawLogsState, isFetching];
};