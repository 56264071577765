import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Alert,
  Table,
} from "reactstrap"

import ApiUsageChart from "./ApiUsageChart"

import { withRouter } from "../../tsxParser/router-v6";
import VerticalLayout from "../../components/VerticalLayout/";
import Breadcrumb from "../../components/Common/Breadcrumb";
import WelcomeCard from "./WelcomeCard";
import BgImage from "../../assets/images/man-neon-suit.png";
import xrImage from "../../assets/images/xr-logo.png";
import thFlag from "../../assets/images/th-flag.png";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import { useAccessTokenWithCurrentMonthUsageData, useApiUsageData, useCurrentUserData } from "./data";
import { customerPortalUrl } from "constants/const";
import { useAllClientAccountsData } from "pages/ClientAccounts/data";
import { AccessToken, getAccessTokenWithCurrentMonthUsageByOrganizationId } from "apis/accessToken";

const ClientDashboard: React.FC = () => {

  const [allAccounts] = useAllClientAccountsData();
  const [apiUsageData, isApiUsageFetching] = useApiUsageData();

  const [currentUser, isFetchingCurrentUser] = useCurrentUserData();
  const [isRedirect, setIsRedirect] = useState(false);
  const [accessTokens, setAccessTokens] = useState<AccessToken[]>([]);

  const getClientSubscriptionStatusById = (id?: string): boolean => {
    if (!id) return false;
    let isSubscriptionActive = false;
    allAccounts.data.forEach(e => {
      if (e._id === id) {
        isSubscriptionActive = e.isSubscriptionActive;
      }
    })
    return isSubscriptionActive;
  }

  const tokensTableBody = () => {
    let table = accessTokens.map((val, i) => {
      return (
        <tr key={"token-table-" + i}>
          <th>{val.name}</th>
          <td>{val.currentMonthUsage}</td>
        </tr>
      );
    });
    return table;
  }

  useEffect(() => {
    if (isFetchingCurrentUser || currentUser === null) return;
    if (currentUser?.userType === "ADMIN" && !isRedirect) {
      setIsRedirect(true);
      window.location.replace("/dashboard");
    }
    if (!currentUser.organizationId) return;
    getAccessTokenWithCurrentMonthUsageByOrganizationId(currentUser.organizationId).then((tokens: AccessToken[]) => {
      if (tokens) {
        setAccessTokens(tokens);
      }
    })

  }, [currentUser]);

  return (
    <VerticalLayout>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | XR Arena</title>
        </MetaTags>
        <Container fluid>
          {(currentUser && !getClientSubscriptionStatusById(currentUser?.organizationId)) &&
            <Alert color="warning">
              Your account is not fully set up yet. Please manage your subscription in the payment menu.
            </Alert>
          }
          <Breadcrumb title="Console" breadcrumbItem="Dashboard" />
          <Row>
            <Col>
              <Card className="overflow-hidden pb-3">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col>
                      <img src={BgImage} alt="" className="img-fluid" width="100%" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="5">
                      <div className="pt-4">
                        <Row>
                          <Col xs="6" id="xr-col-container">
                            <p className="text-muted mb-0">Total Games</p>
                            <h5 className="font-size-24 mt-4">{accessTokens ? accessTokens.length : 0}</h5>
                          </Col>
                          <Col xs="6" id="xr-col-container">
                            <p className="text-muted mb-0">Total API Requests</p>
                            <h5 className="font-size-24 mt-4">{apiUsageData[0]?.dataCount ? apiUsageData[0]?.dataCount : 0}</h5>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col sm="2" id="xr-col-container">
                      <div className="avatar-xl profile-xr-wid mb-4">
                        <img
                          src={xrImage}
                          alt=""
                          className="img-thumbnail rounded-circle"
                        />
                      </div>
                      <h3 className="mt-0">XR Arena</h3>
                    </Col>
                    <Col sm="5">
                      <div className="pt-4">
                        <Row>
                          <Col xs="6" id="xr-col-container">
                            <p className="text-muted mb-0">Location</p>
                            <div className="mt-4">
                              <img
                                src={thFlag}
                                alt=""
                                width={32}
                                className="img-thumbnail rounded-circle"
                              />
                            </div>
                          </Col>
                          <Col xs="6" id="xr-col-container">
                            <div className="payment-icon">
                              <i className="bx bxl-visa me-2" />
                              <i className="bx bxl-mastercard" />
                            </div>
                            <Link
                              to="/payment"
                              // to={customerPortalUrl + `?prefilled_email=${currentUser?.email.replace("@", "%40")}`}
                              // target="_blank"
                              className="btn btn-primary btn-md px-4"
                              id="client-manage-payment-btn"
                            >
                              Manage Payment <i className="mdi mdi-arrow-right ms-1"></i>
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={7}>
              {/* {isAdmin() && hourlyData?.length > 0 && <ApiHourlyChart apiUsageData={hourlyData} />} */}
              <ApiUsageChart apiUsageData={apiUsageData} />
            </Col>
            <Col lg={5}>
              <WelcomeCard apiUsageData={apiUsageData} />
              <Card>
                <CardBody>
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Game Name</th>
                        <th>This Month Usage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tokensTableBody()}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </VerticalLayout>
  )
}

export default withRouter(ClientDashboard);
