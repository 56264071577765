import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux";
import store from "./store";
import reportWebVitals from './reportWebVitals';
import React from "react";
import { createRoot } from 'react-dom/client';

const element = document.getElementById('root') as HTMLElement;
const root = createRoot(element!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();