import { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
// import firebase from 'firebase/compat/app';
import {
    Container,
    Row,
    Card,
    Table,
    CardBody,
    Button,
    Label,
    Input,
    Spinner,
    Modal,
    FormGroup,
} from "reactstrap";

import { withRouter } from "../../tsxParser/router-v6";
import VerticalLayout from "../../components/VerticalLayout";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useAllTokensData } from "./data";
import { Box } from "components/Common/Layout/Layout";
import { useAllClientAccountsData } from "pages/ClientAccounts/data";
import { AccessToken, createOrUpdateAccessToken, generateV2AccessToken } from "apis/accessToken";
import { useCurrentUserData } from "pages/ClientDashboard/data";

const AccessTokens = () => {
    const [isAllowGenerateNewToken, setIsAllowGenerateNewToken] = useState(false);
    const [generatedSdkToken, setGeneratedSdkToken] = useState<string | undefined>();

    const [allTokens] = useAllTokensData();
    const [allAccounts] = useAllClientAccountsData();

    // MODAL
    const [openAccessTokenModel, setOpenAccessTokenModel] = useState(false);
    const [currentAccessTokenId, setCurrentAccessTokenId] = useState("");
    const [currentClientAccountId, setCurrentClientAccountId] = useState("");

    // IN FORM
    const [isEditMode, setIsEditMode] = useState(false);
    const [isShowSdkToken, setIsShowSdkToken] = useState(false);
    const [name, setName] = useState("");
    // const [clientAccountId, setCurrentClientAccountId] = useState("");
    const [note, setNote] = useState("");
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    // CHECK PERMISSION
    const [currentUser, isFetchingCurrentUser] = useCurrentUserData();
    const [isRedirect, setIsRedirect] = useState(false);
    useEffect(() => {
        if (isFetchingCurrentUser || currentUser === null) return;
        if (currentUser?.userType !== "ADMIN" && !isRedirect) {
            setIsRedirect(true);
            window.location.replace("/client_dashboard");
        }
    }, [currentUser]);

    const toggleCreateModel = () => {
        setOpenAccessTokenModel(!openAccessTokenModel);
    }

    const onGenerateNewSdkToken = async (accessTokenId: string) => {
        if (currentClientAccountId === "") return;
        generateV2AccessToken(accessTokenId, currentClientAccountId, true).then(generatedToken => {
            const tokenToDisplay = `Bearer ${generatedToken}`
            setGeneratedSdkToken(tokenToDisplay)
            setIsShowSdkToken(true)
        })
        return
    }

    const getClientNameById = (id: string): string => {
        let name = "";
        allAccounts.data.forEach(e => {
            if (e._id === id) {
                name = e.displayName;
            }
        })
        return name;
    }

    const getClientSubscriptionStatusById = (id: string): boolean => {
        let isSubscriptionActive = false;
        allAccounts.data.forEach(e => {
            if (e._id === id) {
                isSubscriptionActive = e.isSubscriptionActive;
            }
        })
        return isSubscriptionActive;
    }

    const onCreateOrUpdateAccessToken = async () => {
        if (currentClientAccountId === "" || name === "") return;
        setIsSubmitClicked(true);
        const token: AccessToken = {
            _id: currentAccessTokenId,
            name: name,
            createdAt: new Date().toISOString(),
            organizationId: currentClientAccountId,
            note: note
        }
        if (currentAccessTokenId === null || currentAccessTokenId === "") {
            delete token._id
        }
        // console.log("token", token)
        const newAccessTokenId = await createOrUpdateAccessToken(token);
        if (!isEditMode) {
            setCurrentAccessTokenId(newAccessTokenId);
            await onGenerateNewSdkToken(newAccessTokenId); // in case use state won't update yet
        } else {
            window.location.reload();
        }
    }

    const tokensTableBody = () => {
        let table = allTokens.data.map((val, i) => {
            let date = new Date(val.createdAt);
            return (
                <tr key={"token-table-" + i}>
                    <th>{val.name}</th>
                    <td>{getClientNameById(val.organizationId)}</td>
                    <td>{val.currentMonthUsage}</td>
                    <td>{date.toLocaleString()}</td>
                    <td>{getClientSubscriptionStatusById(val.organizationId) ?
                        <Button disabled color="success" size="sm">ACTIVE</Button>
                        :
                        <Button disabled color="danger" size="sm">INACTIVE</Button>
                    }
                    </td>
                    <td>
                        <Button
                            color="primary"
                            size="sm"
                            className="btn px-4"
                            onClick={() => {
                                if (val._id === null) return;
                                // console.log("val", val);
                                setIsEditMode(true);
                                setCurrentClientAccountId(val.organizationId);
                                setCurrentAccessTokenId(val._id!);
                                setName(val.name);
                                setNote(val.note);
                                toggleCreateModel();
                            }}
                            disabled={val._id === null}
                        >
                            View | Edit
                        </Button>
                    </td>
                </tr>
            );
        });
        return table;
    }

    useEffect(() => {
        if (!openAccessTokenModel) {
            setIsEditMode(false);
        }
    }, [openAccessTokenModel])

    return (
        <VerticalLayout>
            <div className="page-content">
                <MetaTags>
                    <title>Access Tokens | XR Arena</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Console" breadcrumbItem="Access tokens" />

                    <div className="mb-4">
                        <button
                            type="button"
                            className="btn btn-primary btn-label"
                            onClick={() => {
                                toggleCreateModel();
                            }}
                        >
                            New Token <i className="bx bx-plus label-icon"></i>
                        </button>
                        <Modal
                            isOpen={openAccessTokenModel}
                            toggle={() => {
                                toggleCreateModel();
                            }}
                            centered
                            size="md"
                        >
                            <div className="modal-header mx-4 mt-2" id="model-header-mxr">
                                <h5 className="modal-title mt-0">
                                    <i className="bx bx-list-ul"></i> Create a Token
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="my-4 mx-4">
                                    <div className="token-name mb-3">
                                        <Label className="mb-2">Token name / App name *</Label>
                                        <Input
                                            id="token-name"
                                            placeholder="Enter Token Name"
                                            type="text"
                                            className="form-control"
                                            defaultValue={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="token-name mb-3">
                                        <Label className="mb-2">Assign client to this token (Cannot edit) *</Label>
                                        <Input
                                            type="select"
                                            name="select"
                                            id="clientAccountId"
                                            disabled={isEditMode}
                                            onChange={e => {
                                                if (e.target.value) {
                                                    // console.log("onChange select", e.target.value)
                                                    setCurrentClientAccountId(e.target.value);
                                                }
                                            }}>
                                            {isEditMode ?
                                                <option>{getClientNameById(currentClientAccountId)}</option>
                                                :
                                                <option>--Select Client Name--</option>
                                            }
                                            {allAccounts.data.map(e => <option value={e._id}>{e.displayName}</option>)}
                                        </Input>
                                    </div>
                                    <div className="token-name mb-3">
                                        <Label className="mb-2">Note</Label>
                                        <Input
                                            id="token-name"
                                            placeholder="Enter any note for this token"
                                            type="text"
                                            className="form-control"
                                            defaultValue={note}
                                            onChange={(e) => setNote(e.target.value)}
                                        />
                                    </div>
                                    {(isShowSdkToken || isEditMode) &&
                                        <div className="mb-3">
                                            <Box fullWidth display="flex" justifyContent="space-between">
                                                <Box display="flex" alignItem="center">
                                                    <Label>Access Token</Label>
                                                </Box>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Box display="flex" alignItem="center" className="text-nowrap me-5">
                                                        Allow Gen New Token
                                                    </Box>
                                                    <Box display="flex" justifyContent="flex-end">
                                                        <FormGroup switch>
                                                            <Input
                                                                id="allow-gen-new-token"
                                                                className="fs-3"
                                                                type="switch"
                                                                role="switch"
                                                                color="#fff"
                                                                defaultChecked={isAllowGenerateNewToken}
                                                                onChange={(e) => setIsAllowGenerateNewToken(e.target.checked)}
                                                            />
                                                        </FormGroup>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Row>
                                                <p className="m-b-15">
                                                    <code>The token will only be shown once its created for security reason. Please save your key at a secured place.</code>
                                                </p>
                                            </Row>
                                            <Input
                                                id="token-name"
                                                type="text"
                                                className="form-control mb-3"
                                                readOnly
                                                placeholder={generatedSdkToken}
                                            />
                                            <Box display="flex" fullWidth justifyContent="flex-end">
                                                {isEditMode &&
                                                    <Button
                                                        color="primary"
                                                        outline
                                                        className="btn px-3 me-2"
                                                        onClick={() => onGenerateNewSdkToken(currentAccessTokenId)}
                                                        disabled={!isAllowGenerateNewToken || !!generatedSdkToken}
                                                    >
                                                        <i className="bi bi-file-earmark-plus fs-6 me-2" />Generate Token
                                                    </Button>
                                                }
                                                <Button
                                                    color="secondary"
                                                    outline
                                                    className="btn px-3"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(generatedSdkToken!);
                                                    }}
                                                    disabled={!generatedSdkToken}
                                                >
                                                    <i className="bi bi-copy fs-6 me-2" />Copy Token
                                                </Button>
                                            </Box>
                                        </div>
                                    }
                                    <div className="mt-5 d-flex flex-wrap gap-2">
                                        <Button
                                            color={isShowSdkToken ? "primary" : "secondary"}
                                            className="btn px-4 me-2"
                                            onClick={() => {
                                                setOpenAccessTokenModel(false);
                                                window.location.reload();
                                            }}
                                        >
                                            {isShowSdkToken ? "Close" : "Cancel"}
                                        </Button>
                                        {!isShowSdkToken &&
                                            <Button
                                                color="primary"
                                                className="btn px-4"
                                                onClick={() => onCreateOrUpdateAccessToken()}
                                                disabled={name === "" || currentClientAccountId === "" || isSubmitClicked}
                                            >
                                                {isEditMode ? "Update" : "Create"} {isSubmitClicked && <Spinner type="grow" className="ms-2" style={{ width: "1rem", height: "1rem" }} color="white" />}

                                            </Button>
                                        }

                                    </div>

                                </div>
                            </div>
                        </Modal>
                    </div>

                    <Card>
                        <CardBody>
                            <div className="table-responsive">
                                {allTokens.isFetching ?
                                    <Spinner type="grow" className="ms-2" color="primary" />
                                    :
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Token Name</th>
                                                <th>Client Name</th>
                                                <th>Monthly Usage</th>
                                                <th>Created at</th>
                                                <th>Token Status</th>
                                                <th>View Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tokensTableBody()}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </VerticalLayout>
    );
};

export default withRouter(AccessTokens);