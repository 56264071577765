import axios from "axios";

import { MXRConsoleBackendURL } from './const';
import { AggregatedData, RequestLog } from "pages/Dashboard/data"
import { generateBaseHeader } from './apiHelper';

export const getLogByOrganization = async (): Promise<AggregatedData[]> => {
    const header = await generateBaseHeader()

    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/log/get-logs-by-organization-id",
        headers: {
            ...header,
        },
        data: {}
    })

    if (resp.status === 200) {
        return resp.data
    }

    return [];
}

export const getHourlyUsage = async (firebaseUid: string): Promise<AggregatedData[]> => {
    const header = await generateBaseHeader()

    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/log/get-hourly-logs",
        headers: {
            ...header,
        },
        data: {
            firebaseAuthUid: firebaseUid,
        }
    })

    if (resp.status === 200) {
        return resp.data
    }

    return [];
}

export const getAllLogs = async (): Promise<AggregatedData[]> => {
    const header = await generateBaseHeader()

    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/log/get-all-logs",
        headers: {
            ...header,
        },
        data: {}
    })

    if (resp.status === 200) {
        return resp.data
    }

    return [];
}