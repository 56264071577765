import { Navigate, Outlet, useLocation } from "react-router-dom"

export const PrivateRoute = () => {
  let location = useLocation();

  if (!localStorage.getItem("xr-arena-user")) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}
