import { useEffect, useState } from 'react';

import { getAllAccessTokens, AccessToken, getAccessTokenWithCurrentMonthUsageByOrganizationId } from "../../apis/accessToken";

interface AllTokensFetch {
    data: AccessToken[]
    isFetching: boolean
    isFinished: boolean
}

export const useAllTokensData = () => {

    const [dataState, setDataState] = useState<AllTokensFetch>({ data: [], isFetching: true, isFinished: false });

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setDataState({ ...dataState, isFetching: true });
                let tokens = await getAllAccessTokens();
                if (!tokens) tokens = [];

                // prep count usage
                for (let i = 0; i < tokens.length; i++) {
                    const allTokens = await getAccessTokenWithCurrentMonthUsageByOrganizationId(tokens[i].organizationId);
                    let count = 0;
                    allTokens.forEach(e => {
                        if ((e._id === tokens[i]._id) && e.currentMonthUsage) {
                            count = e.currentMonthUsage
                        }
                    });
                    tokens[i].currentMonthUsage = count;
                }

                setDataState({
                    ...dataState,
                    data: tokens,
                    isFetching: false,
                    isFinished: true,
                });
            } catch (e) {
                console.log(e);
                setDataState({ ...dataState, isFetching: false });
            }
        };

        if (localStorage.getItem("xr-arena-user")) {
            fetchDataFromApi();
        }

    }, []); // Runs once

    return [dataState];
};