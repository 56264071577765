import React, { useEffect, useRef, useState } from "react"
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import { Modal, ModalHeader, ModalBody, Button, Col } from "reactstrap";

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { Link, useLocation } from "react-router-dom"

import { withRouter } from "../../tsxParser/router-v6";
import { OrganizationType } from "store/organization/types";
import { customerPortalUrl } from 'constants/const';
import { ApplicationState } from "store/data";
import { PermissionType, UserPermission } from "store/permissions/types";
import { Box } from "components/Common/Layout/Layout";
import { useCurrentUserData } from "pages/ClientDashboard/data";


interface StateProps {
  organization: OrganizationType
  permission?: PermissionType
}

interface RouterProps {
  location: any
}

type Props = StateProps & RouterProps

const SidebarContent = (props: Props) => {
  const ref = useRef<SimpleBar>();
  const isSubscriptionExpired = new Date(props.organization.subscriptionExpireAt) < new Date(Date.now())
  const urlParams = new URLSearchParams(window.location.search);
  const checkoutParams = urlParams.get('checkout');
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);

  // CHECK PERMISSION
  const [currentUser, isFetchingCurrentUser] = useCurrentUserData();

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul!.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current!.recalculate()
  })

  useEffect(() => {
    if (checkoutParams === 'success') {
      setCheckoutModalOpen(true);
    }
  }, [])

  const closeCheckoutModal = () => {
    setCheckoutModalOpen(false);
  }

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current!.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item: any) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      {/* @ts-ignore */}
      <SimpleBar className="h-100" ref={ref}>
        {!isFetchingCurrentUser &&
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {currentUser?.userType === "ADMIN" ?
                <>
                  <li className="menu-title">{"ADMIN CONSOLE"}</li>
                  <li>
                    <Link to="/dashboard" className=" ">
                      <i className="bx bx-home-circle"></i>
                      <span>{"Dashboard"}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/access_token" className=" ">
                      <i className="bx bx-list-ul"></i>
                      <span>{"Access Token"}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/client_account" className=" ">
                      <i className="bx bx-user-circle"></i>
                      <span>{"Client Account"}</span>
                    </Link>
                  </li>
                </>
                :
                <>
                  <li className="menu-title">{"CLIENT CONSOLE"}</li>
                  <li>
                    <Link to="/client_dashboard" className=" ">
                      <i className="bx bx-home-circle"></i>
                      <span>{"Client Dashboard"}</span>
                    </Link>
                  </li>
                </>
              }
            </ul>

            <div id="logout-btn-left-panel-container">
              <Link to="/logout">
                <Button
                  color="primary"
                  block
                  // onClick={toggleModal}
                  data-testid="upload-button-full"
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItem="center"
                  >
                    <Col lg={2}><i className="font-size-16 mdi mdi-logout" /></Col>
                    <Col lg={4}>Logout</Col>
                  </Box>
                </Button>
              </Link>
            </div>
          </div>
        }

      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    organization: state.Organization,
    permission: state.Permissions.find(permission => permission.organizationId === state.Organization._id)
  }
}

export default connect(mapStateToProps)(withRouter((SidebarContent)))
export { SidebarContent }
