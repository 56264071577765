import { useEffect, useState } from 'react';
import { getAllOrganizations } from 'apis/organization';
import { OrganizationType } from 'store/organization/types';
import { getAccessTokenWithCurrentMonthUsageByOrganizationId } from 'apis/accessToken';

interface AllClientAccountsFetch {
    data: OrganizationType[]
    isFetching: boolean
    isFinished: boolean
}

export const useAllClientAccountsData = () => {

    const [dataState, setDataState] = useState<AllClientAccountsFetch>({ data: [], isFetching: true, isFinished: false });

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setDataState({ ...dataState, isFetching: true });
                let accounts = await getAllOrganizations();
                accounts = accounts.filter((acc) => acc.displayName !== "ADMIN");
                // console.log("accounts", accounts);
                if (!accounts) accounts = [];

                // prep count usage
                for (let i = 0; i < accounts.length; i++) {
                    let allTokens = await getAccessTokenWithCurrentMonthUsageByOrganizationId(accounts[i]._id);
                    if (!allTokens) allTokens = [];
                    // console.log("allTokens", allTokens);
                    let count = 0;
                    allTokens.forEach(e => {
                        if ((e.organizationId === accounts[i]._id) && e.currentMonthUsage) {
                            count += e.currentMonthUsage
                        }
                    });
                    accounts[i].currentMonthUsage = count;
                }

                setDataState({
                    ...dataState,
                    data: accounts,
                    isFetching: false,
                    isFinished: true,
                });
            } catch (e) {
                console.log(e);
                setDataState({ ...dataState, isFetching: false });
            }
        };

        if (localStorage.getItem("xr-arena-user")) {
            fetchDataFromApi();
        }
    }, []); // Runs once

    return [dataState];
};