import { getOrganizationByOrganizationId, getOrganizationByUserId } from 'apis/organization'
import { initOrganization, changeOrganization, initPermissions } from './actions'
import { Dispatch } from 'redux'
import { getAllPermissionsForUser } from 'apis/permission';

const initOrganizationData = async (dispatch: Dispatch) => {
    const obj = JSON.parse(localStorage.getItem("xr-arena-user") || "");
    const organization = await getOrganizationByUserId(obj.firebaseUid);
    if (organization !== undefined) {
        dispatch(initOrganization(organization));
    }
}

const changeOrganizationData = async (organizationId: string, dispatch: Dispatch) => {
    const organization = await getOrganizationByOrganizationId(organizationId);
    if (organization !== undefined) {
        dispatch(changeOrganization(organization));
    }
}

const initPermissionsData = async (dispatch: Dispatch) => {
    const permissions = await getAllPermissionsForUser()
    if (permissions !== undefined) {
        dispatch(initPermissions(permissions));
    }
}

export { initOrganizationData, changeOrganizationData, initPermissionsData }
