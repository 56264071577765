const stage = process.env.REACT_APP_STAGE

export const customerPortalUrl = stage === "prod"
    ? "https://billing.stripe.com/p/login/eVa17K0rLbeI5BmaEE"
    : "https://billing.stripe.com/p/login/test_00gaIcayparu58YeUU"
export const pricingTableId = stage === "prod"
    ? "prctbl_1OFULSBHW22DMiPl0oMeDSaj"
    : "prctbl_1OBUH2BHW22DMiPlCkTXmYzz"

export const pricingTablePk = stage === "prod"
    ? "pk_live_51O39qUBHW22DMiPlFMiVWenOysOXW9mANdTrqql5Hh3cPKOqBufrO9GaLrDu8aHS3HzE9d3K5NNSQ4lSPBtTXXDN00QXDJKtBx"
    : "pk_test_51O39qUBHW22DMiPl8jUOgd2kyy9zxzTXJBI3RLYh6FHU1uiJ80967nbX8IP11Y0w3CXMeNOzBaoywgr2BxCid6vt00GkzSeQIy"

export const isAdmin = () => {
    const obj = JSON.parse(localStorage.getItem("xr-arena-user")!);
    return obj && obj.firebaseUid === "3wrGUxxsnqbnZlTnXMfOb0GII4v1" // isAdmin is to be removed
}

export const defaultOrganizationName = "My First Team"

export const isV2Token = true

export const costPerRequest = 0.2