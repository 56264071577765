import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { withRouter } from "../../../tsxParser/router-v6"

// users
import user1 from "../../../assets/images/dummy-user.png"

const ProfileMenu = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [userProfileImageURL, setUserProfileImageURL] = useState(user1)
  const [username, setusername] = useState("Admin")

  const profileImageImageOnError = (e: any) => {
    if (e !== null) setUserProfileImageURL(user1);
  }

  useEffect(() => {
    if (localStorage.getItem("xr-arena-user")) {
      const obj = JSON.parse(localStorage.getItem("xr-arena-user")!)
      if (obj.displayName === null || obj.displayName === undefined) {
        obj.displayName = obj.email.split("@")[0].toUpperCase()
      }
      setusername(obj.displayName)

      if (obj.photoURL) setUserProfileImageURL(obj.photoURL)
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={userProfileImageURL}
            // onLoad={profileImageOnLoad}
            onError={profileImageImageOnError}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <div className="dropdown-divider"/> */}
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{"Logout"}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = (state: any) => {
  const { error, success } = state.Profile
  return { error, success }
}

export default
  withRouter(connect(mapStatetoProps, {})(ProfileMenu));

