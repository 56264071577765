import axios from "axios";

import { MXRConsoleBackendURL } from './const';
import { generateBaseHeader } from './apiHelper';

export interface AccessToken {
    _id?: string
    name: string
    organizationId: string
    createdAt: string
    note: string
    currentMonthUsage?: number
}

export const createOrUpdateAccessToken = async (token: AccessToken): Promise<string> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/access-token/create-or-update",
        headers: {
            ...header,
        },
        data: JSON.stringify(token)
    })

    if (resp.status === 200) {
        return resp.data
    }

    return "";
}

export const getAllAccessTokens = async (): Promise<AccessToken[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/access-token/get",
        headers: {
            ...header,
        },
    })

    if (resp.status === 200) {
        // console.log("getAllAccessTokens success");
        return resp.data
    }

    return [];
}

export const getAccessTokenByOrganizationId = async (): Promise<AccessToken[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/access-token/get-by-organization-id",
        headers: {
            ...header,
        },
    })

    if (resp.status === 200) {
        // console.log("getAllAccessTokens success");
        return resp.data
    }

    return [];
}

export const getAccessTokenWithCurrentMonthUsageByOrganizationId = async (orgId: string): Promise<AccessToken[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/access-token/get-with-current-month-usage-by-organization-id",
        headers: {
            ...header,
        },
        data: JSON.stringify({
            organizationId: orgId
        })
    })
    if (resp.status === 200) {
        // console.log("getAccessTokenWithCurrentMonthUsageByOrganizationId success");
        return resp.data
    }

    return [];
}

export const deleteAccessToken = async (accessTokenId: string) => {
    const header = await generateBaseHeader()
    await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/access-token/delete",
        headers: {
            ...header,
        },
        data: JSON.stringify({
            accessTokenId: accessTokenId
        })
    })
}

export const generateV2AccessToken = async (accessTokenId: string, organizationId: string, isPermanent = false): Promise<string> => {
    const header = await generateBaseHeader()

    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/access-token/create-v2",
        headers: {
            ...header,
        },
        data: JSON.stringify({
            accessTokenId,
            organizationId,
            isPermanent,
        })
    })

    if (resp.status === 200) {
        return resp.data
    }

    return "";
}
