import axios from "axios";

import { MXRConsoleBackendURL } from './const';
import { generateBaseHeader } from './apiHelper';
import { PermissionType } from 'store/permissions/types'

export const getAllPermissionsForUser = async (): Promise<PermissionType[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/permission/get-by-user-id",
        headers: {
            ...header,
        },
        data: {},
    })

    if (resp.status === 200) {
        return resp.data
    }
    return []
}

export const getAllPermissionsForOrganization = async (organizationId: string): Promise<PermissionType[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/permission/get-by-org-id",
        headers: {
            ...header,
        },
        data: JSON.stringify({
            _id: organizationId
        }),
    })

    if (resp.status === 200) {
        return resp.data
    }
    return []
}

export const createOrUpdatePermission = async (permission: PermissionType): Promise<number> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/permission/create-or-update",
        headers: {
            ...header,
        },
        data: JSON.stringify(permission),
    })
    return resp.status
}
