import { Routes, Route, Navigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { initOrganizationData, initPermissionsData } from 'store/utils'

// Import all middleware
import { PrivateRoute } from "./routes/route"

// Import Firebase Configuration file
import { initFirebaseBackend, firebaseConfig, getFirebaseBackend } from "./helpers/firebase_helper"
import firebase from 'firebase/compat/app';

// Private Pages
import Dashboard from "./pages/Dashboard/Dashboard"
import AccessTokens from "pages/AccessTokens/AccessTokens"
import ClientAccounts from "pages/ClientAccounts/ClientAccounts"
import ClientDashboard from "pages/ClientDashboard/ClientDashboard"

// Public Pages
import Login from "./pages/Authentication/Login"
import Logout from "./pages/Authentication/Logout"
import Pages404 from "./pages/NotFound"

// Import scss
import "./assets/scss/theme.scss"
import "./App.css"
import Register from "pages/Authentication/Register"
import Payment from "pages/Payment/Payment"


// init firebase backend
initFirebaseBackend(firebaseConfig)

const App = () => {
  const dispatch = useDispatch();
  const firebaseBackend = getFirebaseBackend();

  firebaseBackend.authChangeCallback = (user: firebase.User | null) => {
    if (!user) {
      return
    }
    initOrganizationData(dispatch)
    initPermissionsData(dispatch)
  }

  return (
    <Routes>
      {/* PRIVATE PAGES */}
      <Route element={<PrivateRoute />}>
        <Route path='/' element={<Navigate to="/dashboard" />} />
        {/* ADMIN */}
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/access_token' element={<AccessTokens />} />
        <Route path='/client_account' element={<ClientAccounts />} />

        {/* CLIENT */}
        <Route path='/client_dashboard' element={<ClientDashboard />} />
        <Route path='/payment' element={<Payment />} />

      </Route>

      {/* PUBLIC PAGES */}
      <Route path='/login' element={<Login />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/register' element={<Register />} />
      <Route path='*' element={<Pages404 />} />
    </Routes>
  );
}


export default App
export { App };
