import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../tsxParser/router-v6";
import { connect } from 'react-redux'

import VerticalLayout from "../../components/VerticalLayout";
import { OrganizationType } from "store/organization/types";
import { customerPortalUrl, pricingTableId, pricingTablePk } from 'constants/const';
import { ApplicationState } from "store/data";
import { useCurrentUserData } from "pages/ClientDashboard/data";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

interface StateProps {
    organization: OrganizationType
}

const Payment = (props: StateProps) => {
    const [currentUser, isFetchingCurrentUser] = useCurrentUserData();
    const [isRedirect, setIsRedirect] = useState(false);

    useEffect(() => {
        if (isFetchingCurrentUser || currentUser === null) return;
        if (currentUser?.userType === "ADMIN" && !isRedirect) {
            setIsRedirect(true);
            window.location.replace("/dashboard");
        }
    }, [currentUser]);

    const obj = JSON.parse(localStorage.getItem("xr-arena-user")!);
    const isSubscriptionExpired = new Date(props.organization.subscriptionExpireAt) < new Date(Date.now())

    useEffect(() => {
        if (!isSubscriptionExpired && currentUser && !isRedirect) {
            setIsRedirect(true);
            window.open(customerPortalUrl + `?prefilled_email=${currentUser?.email.replace("@", "%40")}`, "_blank");
            window.location.replace('/client_dashboard');
        }
    }, [isSubscriptionExpired, currentUser])

    if (!obj || !isSubscriptionExpired) {
        return <></>
    }
    const firebaseUid = obj.firebaseUid;
    const email = obj.email;

    return (
        <VerticalLayout>
            <div className="page-content" id="payment-page-content">
                <br /><br /><br /><br />
                <MetaTags>
                    <title>Payment | Graffity Console</title>
                </MetaTags>
                <stripe-pricing-table
                    pricing-table-id={pricingTableId}
                    publishable-key={pricingTablePk}
                    customer-email={email}
                    client-reference-id={firebaseUid}>
                </stripe-pricing-table>
            </div>
        </VerticalLayout >
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        organization: state.Organization
    }
};

export default connect(mapStateToProps)(withRouter(Payment));
