import { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
    Container,
    Card,
    Table,
    CardBody,
    Button,
    Label,
    Input,
    Spinner,
    Modal,
} from "reactstrap";

import { withRouter } from "../../tsxParser/router-v6";
import VerticalLayout from "../../components/VerticalLayout";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useAllClientAccountsData } from "./data";
import { createOrUpdateOrganization } from "apis/organization";
import { useCurrentUserData } from "pages/ClientDashboard/data";
import { OrganizationType } from "store/organization/types";
import { User, createOrUpdateUser } from "apis/user";
import { sendInvitationEmail } from "apis/email";

const ClientAccounts = () => {
    const [allAccounts] = useAllClientAccountsData();

    // MODAL
    const [openAccessTokenModel, setOpenAccessTokenModel] = useState(false);
    const [idForEditingData, setIdForEditingData] = useState("");
    // IN FORM
    const [isEditMode, setIsEditMode] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [note, setNote] = useState("");
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    // CHECK PERMISSION
    const [currentUser, isFetchingCurrentUser] = useCurrentUserData();
    const [isRedirect, setIsRedirect] = useState(false);
    useEffect(() => {
        if (isFetchingCurrentUser || currentUser === null) return;
        if (currentUser?.userType !== "ADMIN" && !isRedirect) {
            setIsRedirect(true);
            window.location.replace("/client_dashboard");
        }
    }, [currentUser]);

    const toggleCreateModel = () => {
        setOpenAccessTokenModel(!openAccessTokenModel);
    }

    const onCreateOrUpdateClientAccount = async () => {
        if (email === "" || name === "") return;
        setIsSubmitClicked(true);
        const account: OrganizationType = {
            _id: idForEditingData,
            updatedAt: new Date().toISOString(),
            subscriptionExpireAt: new Date(0).toISOString(),
            isSubscriptionActive: false,
            subscriptionId: "",
            displayName: name,
            ownerEmail: email,
            address: address,
            note: note,
        }
        // if (idForEditingData === null || idForEditingData === "") {
        //     delete account._id
        // }
        // console.log("account", account);
        const orgId = await createOrUpdateOrganization(account);
        const user: User = {
            firebaseAuthUid: "",
            displayName: "",
            createdAt: Date.now(),
            email: email,
            organizationId: orgId,
            userType: "CLIENT"
        }
        await createOrUpdateUser(user);
        if (!isEditMode) {
            await sendInvitationEmail(email);
        }
        window.location.reload();
    }

    const tokensTableBody = () => {
        let table = allAccounts.data.map((val, i) => {
            let date = new Date(val.updatedAt);
            return (
                <tr key={"token-table-" + i}>
                    <th scope="row">{val.ownerEmail}</th>
                    <td>{val.displayName}</td>
                    <td>{date.toLocaleString().split(",")[0]}</td>
                    <td>{val.currentMonthUsage}</td>
                    <td>{val.isSubscriptionActive ?
                        <Button disabled color="success" size="sm">SUCCESS</Button>
                        :
                        <Button disabled color="danger" size="sm">UNSET | FAILED</Button>
                    }
                    </td>
                    <td>
                        <Button
                            color="primary"
                            size="sm"
                            className="btn px-4"
                            onClick={() => {
                                if (val._id === null) return;
                                setIsEditMode(true);
                                setIdForEditingData(val._id!);
                                setName(val.displayName);
                                setEmail(val.ownerEmail);
                                setAddress(val.address);
                                setNote(val.note);
                                toggleCreateModel();
                            }}
                            disabled={val._id === null}
                        >
                            View | Edit
                        </Button>
                    </td>
                </tr>
            );
        });
        return table;
    }

    return (
        <VerticalLayout>
            <div className="page-content">
                <MetaTags>
                    <title>Client Accounts | XR Arena</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Console" breadcrumbItem="Client Accounts" />

                    <div className="mb-4">
                        <button
                            type="button"
                            className="btn btn-primary btn-label"
                            onClick={() => {
                                toggleCreateModel();
                            }}
                        >
                            New Client <i className="bx bx-plus label-icon"></i>
                        </button>
                        <Modal
                            isOpen={openAccessTokenModel}
                            toggle={() => {
                                toggleCreateModel();
                            }}
                            centered
                            size="md"
                        >
                            <div className="modal-header mx-4 mt-2" id="model-header-mxr">
                                <h5 className="modal-title mt-0">
                                    <i className="bx bx-user-plus"></i> Create New Client
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="my-3 mx-4">
                                    <div className="token-name mb-3">
                                        <Label className="mb-2">Client Name / Company Name *</Label>
                                        <Input
                                            id="name"
                                            placeholder="Enter Client Name"
                                            type="text"
                                            defaultValue={name}
                                            className="form-control"
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="token-name mb-3">
                                        <Label className="mb-2">Client Email *</Label>
                                        <Input
                                            id="email"
                                            placeholder="Enter Email"
                                            type="text"
                                            defaultValue={email}
                                            className="form-control"
                                            disabled={isEditMode}
                                            onChange={(e) => {
                                                let dupAccount = allAccounts.data.find(acc => acc.ownerEmail === e.target.value)
                                                if (dupAccount) {
                                                    // duplicate email
                                                    setEmail("");
                                                } else {
                                                    setEmail(e.target.value);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="token-name mb-3">
                                        <Label className="mb-2">Address</Label>
                                        <Input
                                            id="token-name"
                                            placeholder="Enter Address"
                                            type="text"
                                            defaultValue={address}
                                            className="form-control"
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </div>
                                    <div className="token-name mb-3">
                                        <Label className="mb-2">Note</Label>
                                        <Input
                                            id="token-name"
                                            placeholder="Enter any note for this client"
                                            type="text"
                                            defaultValue={note}
                                            className="form-control"
                                            onChange={(e) => setNote(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-5 d-flex flex-wrap gap-2">
                                        <Button
                                            color="secondary"
                                            className="btn px-4 me-2"
                                            onClick={() => setOpenAccessTokenModel(false)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="primary"
                                            className="btn px-4"
                                            onClick={() => onCreateOrUpdateClientAccount()}
                                            disabled={email === "" || name === "" || isSubmitClicked}
                                        >
                                            Create | Update {isSubmitClicked && <Spinner type="grow" className="ms-2" style={{ width: "1rem", height: "1rem" }} color="white" />}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>

                    <Card>
                        <CardBody>
                            <div className="table-responsive">
                                {allAccounts.isFetching ?
                                    <Spinner type="grow" className="ms-2" color="primary" />
                                    :
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Client ID</th>
                                                <th>Client Name</th>
                                                <th>Created at</th>
                                                <th>Monthly Usage</th>
                                                <th>Subscription Status</th>
                                                {/* <th>Payment Method</th> */}
                                                <th>View Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tokensTableBody()}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </VerticalLayout>
    );
};

export default withRouter(ClientAccounts);