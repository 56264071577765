import { EditorState } from './types'

export const INIT_EDITOR = "INIT_EDITOR"
export const CHANGE_SELECTED_AR_CONTENT = "CHANGE_SELECTED_AR_CONTENT"
export const TOGGLE_UPLOAD_MANAGER = "TOGGLE_UPLOAD_MANAGER"

export const initEditor = (editor: EditorState) => ({
    type: INIT_EDITOR,
    payload: editor,
})

export const changeSelectedArContent = (arContentId: string) => ({
    type: CHANGE_SELECTED_AR_CONTENT,
    payload: arContentId,
})

export const toggleUploadManager = (isOpen: boolean) => ({
    type: TOGGLE_UPLOAD_MANAGER,
    payload: isOpen,
})
