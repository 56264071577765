import firebase from 'firebase/compat/app';

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { updateUser } from "../apis/user"

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

interface SocialLogninData {
  name: string;
  email: string;
  token: string;
  idToken: string;
}

const expireTime = 3600 * 1000; // expire after 1 hour
const cookieExpire = new Date(Date.now() + expireTime)

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY!,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN!,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID!,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET!,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID!,
  appId: process.env.REACT_APP_FIREBASE_APPID!,
}

class FirebaseAuthBackend {
  isLoginUser: boolean | undefined;
  constructor(config: FirebaseConfig) {
    if (config) {
      // Initialize Firebase
      firebase.initializeApp(config);

      // Set persis auth level https://firebase.google.com/docs/auth/web/auth-state-persistence#supported_types_of_auth_state_persistence
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          user.getIdToken(true).then((token: string) => {
            document.cookie = `firebase.token=${token};expires=${cookieExpire.toUTCString()}`;
            updateUser(user).then(() => {
              this.authChangeCallback(user)
            }); // TODO: Update user every time is non-sense but firebase auth is hard to determine new and exist
          })
          let userLocal: any = user["providerData"][0]
          userLocal["firebaseUid"] = user.uid;
          localStorage.setItem("xr-arena-user", JSON.stringify(userLocal));
          this.isLoginUser = true;
        } else {
          this.authChangeCallback(null)
          this.logoutUser()
        }
      });
    }
  }

  authChangeCallback = (user: firebase.User | null) => { }

  logoutUser = () => {
    localStorage.removeItem("xr-arena-user");
    document.cookie = `firebase.token=;expires=${new Date().toUTCString()}`
    this.isLoginUser = false;
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = async (email: string, password: string) => {
    // console.log("loginUser", email, password)
    let provider = new firebase.auth.EmailAuthProvider();
    if (!provider) throw this._handleError("Unable to sign in. Please try again");
    try {
      const result = await firebase.auth().signInWithEmailAndPassword(email, password);
      const user = result.user;
      // console.log("user", user)
      return user
    } catch (error) {
      throw this._handleError(error);
    }
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email: string) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Social Login user with given details
   */
  socialLoginUser = async (type: string) => {
    let provider: any;
    if (type === "google") {
      provider = new firebase.auth.GoogleAuthProvider();
    } else if (type === "facebook") {
      provider = new firebase.auth.FacebookAuthProvider();
    } else if (type === "microsoft") {
      provider = new firebase.auth.OAuthProvider("microsoft.com")
    }
    if (!provider) throw this._handleError("Unable to sign in. Please try again");
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const user = result.user;
      // console.log("user", user)
      return user;
    } catch (error) {
      throw this._handleError(error);
    }
  };

  addNewUserToFirestore = (user: any) => {
    const collection = firebase.firestore().collection("users");
    const { profile } = user.additionalUserInfo!;
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp()
    };
    collection.doc(firebase.auth().currentUser!.uid).set(details);
    return { user, details };
  };

  getCurrentUser = () => {
    return firebase.auth().currentUser;
  };

  getTokenAfterInit = () => {
    const waitForRefreshedToken = (
      resolve: (i: string) => void,
      reject: (i: string) => void
    ) => {
      if (this.isLoginUser !== undefined) {
        const user = this.getCurrentUser()
        if (!user) {
          reject("logout user")
          return
        }
        user.getIdToken().then((token: string) => {
          document.cookie = `firebase.token=${token};expires=${cookieExpire.toUTCString()}`;
          resolve(token)
        })
      } else {
        setTimeout(waitForRefreshedToken.bind(this, resolve, reject), 200);
      }
    }
    return new Promise(waitForRefreshedToken)
  }

  getRefreshedToken = async () => {
    return await this.getTokenAfterInit().then((result => {
      return result;
    })).catch(_ => {
      this.logoutUser()
    })
  }

  verifyUserEmail = (email: string) => {
    // return new Promise((resolve, reject) => {
    //   firebase
    //     .auth()
    //     .sendPasswordResetEmail(email, {
    //       url:
    //         window.location.protocol + "//" + window.location.host + "/login",
    //     })
    //     .then(() => {
    //       resolve(true);
    //     })
    //     .catch(error => {
    //       reject(this._handleError(error));
    //     });
    // });
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error: any) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend: FirebaseAuthBackend;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config: FirebaseConfig) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend, firebaseConfig };
