import { useEffect, useState } from 'react';
import { getLogByOrganization, getHourlyUsage } from 'apis/visualization'
import { isAdmin } from 'constants/const';
import { User, getByFirebaseUid } from 'apis/user';
import { AccessToken, getAccessTokenByOrganizationId, getAccessTokenWithCurrentMonthUsageByOrganizationId } from 'apis/accessToken';

export interface AggregatedData {
    groupId: number;
    dataCount: number;
}

export interface RequestLog {
    _id: String;
    projectRefId: string;
    timestamp: string;
    logType: string;
    actionType: string;
    arContentId: string;
    analyticId: string;
}

export const useCurrentUserData = (): [User | null, boolean] => {

    const [userDataState, setUserDataState] = useState<User | null>(null);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            const obj = JSON.parse(localStorage.getItem("xr-arena-user")!);
            if (!obj.firebaseUid) {
                return null;
            }

            try {
                setIsFetching(true);
                const userData = await getByFirebaseUid(obj.firebaseUid);
                setUserDataState(userData);
                setIsFetching(false)
            } catch (e) {
                console.log(e);
                setIsFetching(false)
            }
        };

        if (localStorage.getItem("xr-arena-user")) {
            fetchDataFromApi();
        }

    }, []); // Runs once

    return [userDataState, isFetching];
};

export const useApiUsageData = (): [AggregatedData[], boolean] => {

    const [apiDataState, setapiDataState] = useState<AggregatedData[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setIsFetching(true);
                const apiData = await getLogByOrganization();
                console.log("apiData", apiData);
                setapiDataState(apiData);
                setIsFetching(false)
            } catch (e) {
                console.log(e);
                setIsFetching(false)
            }
        };

        if (localStorage.getItem("xr-arena-user")) {
            fetchDataFromApi();
        }

    }, []); // Runs once

    return [apiDataState, isFetching];
};

export const useAccessTokenData = (): [AccessToken[], boolean] => {

    const [apiDataState, setapiDataState] = useState<AccessToken[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setIsFetching(true);
                const apiData = await getAccessTokenByOrganizationId();
                // console.log("apiData", apiData);
                setapiDataState(apiData);
                setIsFetching(false)
            } catch (e) {
                console.log(e);
                setIsFetching(false)
            }
        };

        if (localStorage.getItem("xr-arena-user")) {
            fetchDataFromApi();
        }

    }, []); // Runs once

    return [apiDataState, isFetching];
};

export const useAccessTokenWithCurrentMonthUsageData = (orgId: string): [AccessToken[], boolean] => {

    const [apiDataState, setApiDataState] = useState<AccessToken[]>([]);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setIsFetching(true);
                const apiData = await getAccessTokenWithCurrentMonthUsageByOrganizationId(orgId);
                // console.log("apiData", apiData);
                setApiDataState(apiData);
                setIsFetching(false)
            } catch (e) {
                console.log(e);
                setIsFetching(false)
            }
        };

        if (localStorage.getItem("xr-arena-user")) {
            fetchDataFromApi();
        }

    }, []); // Runs once

    return [apiDataState, isFetching];
};