import React from "react";
import {
    Card,
    CardBody,
} from "reactstrap";
import { AggregatedData } from './data';
import { Bar, ChartProps } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface ApiUsageChartProps {
    apiUsageData: AggregatedData[];
}

const convertToChartData = (data: AggregatedData[]) => {
    const dataArray = Array(12).fill(0);
    if (!data) {
        return dataArray;
    }

    data.forEach((element) => {
        dataArray[element.groupId - 1] = element.dataCount;
    })
    return dataArray;
}

const ApiUsageChart = ({ apiUsageData }: ApiUsageChartProps) => {
    const periodData = convertToChartData(apiUsageData)

    // ***** Chart Period Change to be reintroduced *****
    // const [periodType, setPeriodType] = useState("yearly");

    // const onChangeChartPeriod = (pType: any) => {
    //     setPeriodType(pType);

    // };

    const data = {
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ],
        datasets: [
            {
                label: "Api Count",
                backgroundColor: '#556ee6',
                data: periodData,
                borderRadius: 5,
            },
        ],
    }

    const option: ChartProps['options'] = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
                display: false,
            },
            title: {
                display: true,
                text: 'Monthly API Count',
            },
        },
    }

    return (
        <Card id="api-usage-bar-chart">
            <CardBody style={{ height: "502px" }}>
                <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">API Requests</h4>
                </div>
                <React.Fragment>
                    <Bar
                        data={data}
                        options={option}
                    />
                </React.Fragment>
            </CardBody>
        </Card>
    )
}

export default ApiUsageChart;
export { convertToChartData };
export type { ApiUsageChartProps }
