import { AnyAction } from 'redux'

import { INIT_ORGANIZATION, CHANGE_ORGANIZATION } from "./actions"

import { OrganizationType } from "./types";

const INIT_STATE: OrganizationType = {
    _id: '',
    updatedAt: new Date(0).toISOString(),
    subscriptionExpireAt: new Date(0).toISOString(),
    subscriptionId: '',
    isSubscriptionActive: false,
    displayName: '',
    ownerEmail: '',
    address: '',
    note: '',
}

const Organization = (state = INIT_STATE, action: AnyAction) => {
    switch (action.type) {
        case INIT_ORGANIZATION:
            return {
                ...action.payload,
            }
        case CHANGE_ORGANIZATION:
            return {
                ...action.payload,
            }

        default:
            return state
    }
}

export default Organization
