import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import SidebarContent from "./SidebarContent";
import { withRouter } from "../../tsxParser/router-v6";

import logoLightPng from "../../assets/images/logo-light.png";

const Sidebar = () => {

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logoLightPng} alt="" height="6" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="32" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightPng} alt="" height="6" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="32" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = (state: any) => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter((Sidebar)))
