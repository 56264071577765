import axios from "axios";
import firebase from 'firebase/compat/app';

import { MXRConsoleBackendURL } from './const';
import { generateBaseHeader } from './apiHelper';

export interface User {
    firebaseAuthUid: string
    displayName: string
    createdAt: number
    email: string
    organizationId?: string
    userType?: string
}

export const createOrUpdateUser = async (user: User) => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/user/update",
        headers: {
            ...header,
        },
        data: JSON.stringify(user)
    })

    if (resp.status === 200) {
        // console.log("update user success");
        return "updateUser success"
    }
    console.error("updateUser fail");
    return "updateUser fail";
}


export const updateUser = async (user: firebase.User) => {
    const header = await generateBaseHeader()
    const reqUser: User = {
        firebaseAuthUid: user.uid,
        displayName: user.displayName ? user.displayName : "",
        createdAt: Date.parse(user.metadata.creationTime!),
        email: user.email!
    }

    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/user/update",
        headers: {
            ...header,
        },
        data: JSON.stringify(reqUser)
    })

    if (resp.status === 200) {
        // console.log("update user success");
        return "updateUser success"
    }
    console.error("updateUser fail");
    return "updateUser fail";
}

export const getApiToken = async () => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/user/get-api-token",
        headers: {
            ...header,
        },
        data: {}
    })

    if (resp.status === 200) {
        return resp.data
    }
}

export const getByFirebaseUid = async (firebaseAuthUid: string) => {
    // already check in use effect
    // const localData = localStorage.getItem("xr-arena-user")
    // if (!localData) return null;
    // const obj = JSON.parse(localData);
    // if (!obj.firebaseUid) return null;

    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/user/get-by-firebase-uid",
        headers: {
            ...header,
        },
        data: {
            "firebaseAuthUid": firebaseAuthUid
        }
    })

    if (resp.status === 200) {
        return resp.data
    }
}