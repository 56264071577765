import axios from "axios";

import { MXRConsoleBackendURL } from './const';
import { generateBaseHeader } from './apiHelper';

export const sendInvitationEmail = async (recipientEmail: string): Promise<boolean> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: MXRConsoleBackendURL + "/private-api/v1/email/send-invitation-email",
        headers: {
            ...header,
        },
        data: {
            "recipientEmail": recipientEmail
        },
    })

    if (resp.status === 200) {
        return true
    }
    return false
}