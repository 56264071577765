import { AnyAction } from 'redux'

import { INIT_EDITOR, CHANGE_SELECTED_AR_CONTENT, TOGGLE_UPLOAD_MANAGER } from "./actions"

import { EditorState } from "./types";

const INIT_STATE: EditorState = {
    selectedArContentId: '',
    isUploadManagerOpen: false
}

const Editor = (state = INIT_STATE, action: AnyAction) => {
    switch (action.type) {
        case INIT_EDITOR:
            return {
                ...action.payload,
            }
        case CHANGE_SELECTED_AR_CONTENT:
            return {
                ...state,
                selectedArContentId: action.payload
            }
        case TOGGLE_UPLOAD_MANAGER:
            return {
                ...state,
                isUploadManagerOpen: action.Payload
            }

        default:
            return state
    }
}

export { Editor }
