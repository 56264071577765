import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

import ApiUsageChart from "./ApiUsageChart"

import { withRouter } from "../../tsxParser/router-v6";
import VerticalLayout from "../../components/VerticalLayout/";
import Breadcrumb from "../../components/Common/Breadcrumb";
import WelcomeCard from "./WelcomeCard";
import "./Dashboard.css";
import { useCurrentUserData } from "pages/ClientDashboard/data";
import { AggregatedData, useAllLogsData } from "./data";
import { costPerRequest } from "constants/const";

const Dashboard: React.FC = () => {

  const [allLogsData, isAllLogsDataFetching] = useAllLogsData();

  // CHECK PERMISSION
  const [currentUser, isFetchingCurrentUser] = useCurrentUserData();
  const [isRedirect, setIsRedirect] = useState(false);

  const getAllApiRequests = (data: AggregatedData[]) => {
    let sum = 0;
    data.forEach((element) => {
      sum += element.dataCount;
    })
    return sum;
  }

  const convertToChartData = (data: AggregatedData[]) => {
    const dataArray = Array(12).fill(0);
    if (!data) {
      return dataArray;
    }
    data.forEach((element) => {
      dataArray[element.groupId - 1] = element.dataCount;
    })
    return dataArray;
  }

  const getMonthlyEarnings = (data: AggregatedData[]) => {
    let monthData = convertToChartData(data);
    const d = new Date();
    let month = d.getMonth();
    return parseFloat((monthData[month] * costPerRequest).toString()).toFixed(2);
  }

  const getCompareToPreviousMonth = (data: AggregatedData[]) => {
    let monthData = convertToChartData(data);
    const d = new Date();
    let month = d.getMonth();
    let currentMonthEarnings = monthData[month] * costPerRequest;
    if (currentMonthEarnings == 0) return 0;
    let prevMonthEarnings = monthData[month - 1] * costPerRequest;
    return ((currentMonthEarnings - prevMonthEarnings) / currentMonthEarnings) * 100;
  }

  useEffect(() => {
    if (isFetchingCurrentUser || currentUser === null) return;
    if (currentUser?.userType !== "ADMIN" && !isRedirect) {
      setIsRedirect(true);
      window.location.replace("/client_dashboard");
    }
  }, [currentUser]);

  return (
    <VerticalLayout>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | XR Arena</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Console" breadcrumbItem="Dashboard" />

          <Row>
            <Col lg={7}>
              {/* {isAdmin() && hourlyData?.length > 0 && <ApiHourlyChart apiUsageData={hourlyData} />} */}
              <ApiUsageChart apiUsageData={allLogsData} />
            </Col>
            <Col lg={5}>
              <WelcomeCard apiUsageData={[]} />
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Revenue</CardTitle>
                  <Row>
                    <Col sm="6">
                      <p className="text-muted">Monthly earnings</p>
                      <h3>${getMonthlyEarnings(allLogsData)}</h3>
                      <p className="text-muted">
                        <span className={getCompareToPreviousMonth(allLogsData) < 0 ? "text-warning me-2" : "text-success me-2"}>
                          {" "}
                          {getCompareToPreviousMonth(allLogsData)}% <i className="mdi mdi-arrow-up"></i>{" "}
                        </span>{" "}
                        From previous period
                      </p>
                    </Col>
                    <Col sm="6">
                      <p className="text-muted">Yearly earnings</p>
                      <h3>${parseFloat((getAllApiRequests(allLogsData) * costPerRequest).toString()).toFixed(2)}</h3>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </VerticalLayout>
  )
}

export default withRouter(Dashboard);
